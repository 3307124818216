/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Subtitle, Text, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Služby"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1ia4d7c --style2 --full pb--60 pt--60" name={"hero"} border={""} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/898ffea8a240423db0ab04d062e26678_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/898ffea8a240423db0ab04d062e26678_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/898ffea8a240423db0ab04d062e26678_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/898ffea8a240423db0ab04d062e26678_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/898ffea8a240423db0ab04d062e26678_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/898ffea8a240423db0ab04d062e26678_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/898ffea8a240423db0ab04d062e26678_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/898ffea8a240423db0ab04d062e26678_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--102 title-box--invert" content={"Služby"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--60" name={"allq2j1t7dh"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--top" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Rádi pro vás připravíme"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 flex--top" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Image className="--center" src={"https://cdn.swbpg.com/t/18947/15675a5073bf469597c647b1aa01328c_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":244}} srcSet={"https://cdn.swbpg.com/t/18947/15675a5073bf469597c647b1aa01328c_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/15675a5073bf469597c647b1aa01328c_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/15675a5073bf469597c647b1aa01328c_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">STUDENÁ | TEPLÁ KUCHYNĚ</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":650}} content={"<span style=\"color: rgb(0, 0, 0);\">Připravíme nejen kanapky a\nsýrovou mísu, ale i teplé\nobčerstvení</span>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">ČESKÁ | SVĚTOVÁ KUCHYNĚ</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":650}} content={"<span style=\"color: rgb(0, 0, 0);\">Umíme se přizpůsobit vašim\nchutím, uvaříme řízky, guláš, ale i\nfine dining nebo sushi.</span>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">RAUTOVÉ STOLY</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":650}} content={"<span style=\"color: rgb(0, 0, 0);\">Na našich švédských stolech si\nkaždý z vašich hostů vybere právě\nto, na co má zrovna chuť.</span>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">SWEET BAR</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":650}} content={"<span style=\"color: rgb(0, 0, 0);\">Myslíme i na mlsné jazýčky. Sladký\nbar zpestříme oblíbeným tiramisu,\npanna cottou nebo třeba\ndomácími koláči.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">PŘÍPITEK</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":650}} content={"<span style=\"color: rgb(0, 0, 0);\">Víme, jak začít každý důležitý\nevent.<br></span><br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">DEGUSTACE</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":650}} content={"<span style=\"color: rgb(0, 0, 0);\">Zajišťujeme degustace vín, rumů\nnebo také párování jídla s vínem.</span><br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">GRILOVÁNÍ</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":650}} content={"<span style=\"color: rgb(0, 0, 0);\">K létu patří neodmyslitelně\ngrilování steaků, klobásek nebo\nzeleniny.</span><br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">ALKO | NEALKO NÁPOJE</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":650}} content={"<span style=\"color: rgb(0, 0, 0);\">Koktejly, vína, piva, domácí\nlimonády i čerstvé pomerančové\nfresh džusy.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"bvopylrbtxv"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/278c4898e39141c68e479efd9fb8bd4e_s=860x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/18947/278c4898e39141c68e479efd9fb8bd4e_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/278c4898e39141c68e479efd9fb8bd4e_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/278c4898e39141c68e479efd9fb8bd4e_s=860x_.png 860w, https://cdn.swbpg.com/t/18947/278c4898e39141c68e479efd9fb8bd4e_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/3d90f7f936e8478f8cc574bb8f20c69e_s=860x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/18947/3d90f7f936e8478f8cc574bb8f20c69e_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/3d90f7f936e8478f8cc574bb8f20c69e_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/3d90f7f936e8478f8cc574bb8f20c69e_s=860x_.png 860w, https://cdn.swbpg.com/t/18947/3d90f7f936e8478f8cc574bb8f20c69e_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/9ad5f4d84c4c4e4e9922b7b16cc7bb53_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":876}} srcSet={"https://cdn.swbpg.com/t/18947/9ad5f4d84c4c4e4e9922b7b16cc7bb53_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/9ad5f4d84c4c4e4e9922b7b16cc7bb53_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/9ad5f4d84c4c4e4e9922b7b16cc7bb53_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/9ad5f4d84c4c4e4e9922b7b16cc7bb53_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/9ad5f4d84c4c4e4e9922b7b16cc7bb53_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/f20fb32e68b042ba81c68f8a3d2e8f31_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":726}} srcSet={"https://cdn.swbpg.com/t/18947/f20fb32e68b042ba81c68f8a3d2e8f31_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/f20fb32e68b042ba81c68f8a3d2e8f31_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/f20fb32e68b042ba81c68f8a3d2e8f31_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/f20fb32e68b042ba81c68f8a3d2e8f31_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/f20fb32e68b042ba81c68f8a3d2e8f31_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/154a5a52b2654bd69d4ff8c39ae2c6d1_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/18947/154a5a52b2654bd69d4ff8c39ae2c6d1_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/154a5a52b2654bd69d4ff8c39ae2c6d1_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/154a5a52b2654bd69d4ff8c39ae2c6d1_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/d6349c73f0994482b3d020ad694ea059_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":730}} srcSet={"https://cdn.swbpg.com/t/18947/d6349c73f0994482b3d020ad694ea059_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/d6349c73f0994482b3d020ad694ea059_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/d6349c73f0994482b3d020ad694ea059_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/d6349c73f0994482b3d020ad694ea059_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/d6349c73f0994482b3d020ad694ea059_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"p9r00la5yb"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box btn-box--shape2 btn-box--cColor1 mb--30" use={"page"} href={"/kontakt"} content={"CHCI USPOŘÁDAT AKCI"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20 pt--20" style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--4 flex--center" columns={"4"} fullscreen={false}>
            
            <ColumnWrapper className="--right" style={{"maxWidth":215}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/18947/a0ef953d5c73431e80aa9839c213ae08_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":123}} srcSet={"https://cdn.swbpg.com/t/18947/a0ef953d5c73431e80aa9839c213ae08_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/a0ef953d5c73431e80aa9839c213ae08_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/a0ef953d5c73431e80aa9839c213ae08_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--center" style={{"maxWidth":269}} content={"<span style=\"color: rgb(255, 255, 255);\"><span style=\"font-weight: bold;\">E-mail<br></span><br>info@olivacatering.cz</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(255, 255, 255);\"><span style=\"font-weight: bold;\">Telefon<br></span><br>+420 723 101 012</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--center" content={"<span style=\"font-weight: 700; color: rgb(255, 255, 255);\">Sledujte nás</span><br>"}>
              </Text>

              <Text className="text-box text-box--center" content={"<a href=\"https://www.instagram.com/olivacatering_cz/\" style=\"color: rgb(255, 255, 255);\">Instagram</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}